import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Table, { PageSize, TableProps, formatDate } from 'src/global/components/Tables/Table';
import {
  getDataGridOperators,
  getNumberGridOperators,
  getSingleSelectOperators,
  getStringGridOperators,
} from '../../helpers/filterOperators';
import { GridRenderCellParams, ValueOptions } from '@mui/x-data-grid-pro';
import { AvailableExportColumn } from './ExportColumnChooser';
import { downloadTextAsFile } from '../../helpers/downloadFile';
import { prepareCSVData } from '../../helpers/csvFile';
import { GridColDefWithExport } from '../../models/GridColDefWithExport';
import { usePaymentContracts } from '../../contexts/paymentContractsContext';
import FailedIcon from '../icons/FailedIcon';
import CanceledIcon from '../icons/CanceledIcon';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckIcon from '@mui/icons-material/Check';

const RetryPaymentTable: FunctionComponent<TableProps> = ({
  rows,
  rowsPerPageOptions = [PageSize.SMALL, PageSize.MEDIUM, PageSize.LARGE],
  onCellClick,
  query,
  updateQuery,
  rowCount,
  loading = false,
}: TableProps) => {
  const context = usePaymentContracts();
  const [total, setTotal] = useState(0);

  const [columnsToExport, setColumnsToExport] = useState<AvailableExportColumn[]>([]);
  const [exporting, setExporting] = useState(false);
  const [exported, setExported] = useState(false);

  useEffect(() => {
    setTotal(context.retryPaymentResults?.total || 0);
  }, [context.retryPaymentResults?.total]);

  useEffect(() => {
    if (context.retryExportResults?.length && columnsToExport.length) {
      downloadTextAsFile(
        prepareCSVData(columnsToExport, context.retryExportResults),
        'Retry Failed Payment List.csv'
      );
      setColumnsToExport([]);
      setExporting(false);
      setExported(true);
    }
  }, [context.retryExportResults, columnsToExport]);

  useEffect(() => {
    if (columnsToExport.length) {
      setExported(false);
      setExporting(true);

      context.setRetryColumnsToExport(columnsToExport);
      context.queryRetriesPaymentForExport({
        ...query,
        ...{ pageSize: total },
      });
    }
  }, [columnsToExport]);

  const handleColumnsToExportChange = useCallback((cols: AvailableExportColumn[]) => {
    setColumnsToExport(cols);
  }, []);

  const columns: GridColDefWithExport[] = [
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      filterOperators: getSingleSelectOperators(),
      flex: 75,
      align: 'left',
      exportable: true,
      renderCell: (params: GridRenderCellParams): any => {
        const { value } = params;
        switch (value) {
          case 'FAILED':
            return <FailedIcon />;
          case 'ACTIVE':
            return <CalendarMonthIcon />;
          case 'EXPIRED':
            return <AlarmOffIcon color="error" />;
          case 'CANCELED':
            return <CanceledIcon />;
          case 'PROCESSED':
            return <CheckIcon color="success" />;
        }
      },
      getOptionLabel: (p: ValueOptions) => {
        return (
          p as {
            value: any;
            label: string;
          }
        ).label;
      },
      valueOptions: [
        {
          value: 'ACTIVE',
          label: 'ACTIVE',
        },
        {
          value: 'CANCELED',
          label: 'CANCELED',
        },
        {
          value: 'FAILED',
          label: 'FAILED',
        },
        {
          value: 'EXPIRED',
          label: 'EXPIRED',
        },
        {
          value: 'UNKNOWN',
          label: 'UNKNOWN',
        },
      ],
    },
    {
      field: 'apn',
      type: 'string',
      filterOperators: getStringGridOperators(),
      headerName: 'APN',
      flex: 100,
      align: 'left',
      exportable: true,
    },
    {
      field: 'billNumber',
      headerName: 'Bill Number',
      type: 'string',
      filterOperators: getStringGridOperators(),
      flex: 100,
      align: 'left',
      exportable: true,
    },
    {
      field: 'taxYear',
      headerName: 'Tax year',
      type: 'string',
      filterOperators: getNumberGridOperators(),
      flex: 55,
      align: 'left',
      exportable: true,
    },
    {
      field: 'paymentDate',
      headerName: 'Original Payment Date',
      flex: 150,
      align: 'left',
      type: 'date',
      filterOperators: getDataGridOperators(),
      filterable: true,
      exportable: true,
      valueFormatter: formatDate,
    },
    {
      field: 'nextRetryDate',
      headerName: 'Next Retry Date',
      flex: 150,
      align: 'left',
      type: 'date',
      filterOperators: getDataGridOperators(),
      filterable: true,
      exportable: true,
      valueFormatter: formatDate,
    },
    {
      field: 'nextPaymentDate',
      headerName: 'Next Payment Date',
      flex: 150,
      align: 'left',
      type: 'date',
      filterOperators: getDataGridOperators(),
      filterable: true,
      exportable: true,
      valueFormatter: formatDate,
    },
    {
      field: 'lastAttemptDate',
      headerName: 'Last Attempt Date',
      flex: 150,
      align: 'left',
      type: 'date',
      filterOperators: getDataGridOperators(),
      filterable: true,
      exportable: true,
      valueFormatter: formatDate,
    },
  ];

  return (
    <Table
      rows={rows}
      columns={columns}
      columnsToExport={columns as AvailableExportColumn[]}
      rowsPerPageOptions={rowsPerPageOptions}
      onCellClick={onCellClick}
      filterMode={'server'}
      query={query}
      updateQuery={updateQuery}
      rowCount={rowCount}
      loading={loading}
      exportData={handleColumnsToExportChange}
      exporting={exporting || false}
      exported={exported}
    />
  );
};

export default RetryPaymentTable;
