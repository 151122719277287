import { AvailableExportColumn } from '../components/Tables/ExportColumnChooser';

export function prepareCSVData(headers: AvailableExportColumn[], data: any[]): string {
  const headerRow = headers.map((h) => h.headerName);
  const dataFields = headers.map((h) => h.field);
  const prepareRow = (rowData: any): string => {
    const rowItems: string[] = [];
    dataFields.forEach((f) => {
      rowItems.push(rowData[f] !== null ? `"${rowData[f]}"` : ' ');
    });
    return rowItems.join(',');
  };

  return [
    headerRow.join(','),
    ...data.map((d) => {
      return prepareRow(d);
    }),
  ].join('\r\n');
}
