/**
 * Takes a tax year in either a string or number and returns a formatted tax year range.
 *
 * Example:
 *
 * IN: 2020
 * OUT: "2020 - 2021"
 */
export default function formatTaxYear(taxYear: string | number): string {
  const year = parseInt(`${taxYear}`);

  return `${year} - ${year + 1}`;
}
