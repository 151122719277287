import { DefaultContext } from '../models/Context';
import React, { useState } from 'react';
import { useAuth } from './authContext';
import { PageSize } from '../components/Tables/GenericTable';
import { Query } from '../models/Query';
import {
  CortacResponse,
  CortacSubmissionResponse,
  CortacSubmissionsQueryRequest,
  CortacSubmissionsQueryResponse,
  CortacUpcoming,
  CortacUpcomingQueryResponse,
  GenerateCortac,
} from '../models/Cortac';
import { CortacService } from '../services/cortacs';
import { AxiosError } from 'axios';
import {
  filterAndTrimContracts,
  filterCortacs,
  trimCortacs,
} from '../helpers/filterUpcomingCortacs';

import { VoucherContract } from '../models/VoucherContract';
import { PaymentContractsRequest, PaymentContractsResult } from '../models/PaymentContract';
import { getPaymentContracts } from '../services/paymentContracts';
import { flattenDeep, uniq } from 'lodash';
interface CortacsContextType extends DefaultContext {
  cortacLoading: boolean;
  cortacLoaded: boolean;
  cortacsPast?: CortacSubmissionsQueryResponse;
  cortacsUpcoming?: CortacUpcomingQueryResponse;
  refreshList: () => void;
  getPastCortacs: (query?: Query) => void;
  getUpcomingCortacs: (query?: Query) => void;
  getCortacById: (id: string) => Promise<CortacSubmissionResponse | AxiosError | unknown>;
  submit: (cortac: GenerateCortac) => Promise<CortacResponse | AxiosError | unknown>;
  preview: (cortac: GenerateCortac) => Promise<CortacResponse | AxiosError | unknown>;
  getFileContent: (path: string) => Promise<string | AxiosError | unknown>;
  queryUpcomingCortacs: (query: Query, cortacs?: CortacUpcoming[]) => void;
  clearPastCortacs: () => void;
  clearAllUpcomingCortacs: () => void;
  installmentsLoading: boolean;
  installmentsLoaded: boolean;
  getInstallments: (taxRollId: string, taxYear: number) => Promise<number[] | AxiosError>;
  allCortacsUpcoming?: CortacUpcoming[];
  allVoucherContracts?: VoucherContract[];
  summary: {
    totalNumber?: number;
    needsToSettle?: number;
    totalAmount?: number;
    totalAmountRemainingToSettle?: number;
  };
}

function getPageTotal(cortacsSize: number, pageSize: number): number {
  if (cortacsSize === 0) {
    return 0;
  }

  return Math.round(cortacsSize / (pageSize || 1));
}

const CortacsContext = React.createContext<CortacsContextType>(null!);

export function CortacsProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [cortacLoading, setCortacLoading] = React.useState<boolean>(false);
  const [cortacLoaded, setCortacLoaded] = React.useState<boolean>(false);
  const [installmentsLoading, setInstallmentsLoading] = React.useState<boolean>(false);
  const [installmentsLoaded, setInstallmentsLoaded] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [lastQuery, setLastQuery] = React.useState<Query | undefined>();
  const [refreshQuery, setRefreshQuery] = React.useState<boolean>(false);
  const [cortacsPast, setCortacsPast] =
    React.useState<CortacSubmissionsQueryResponse | undefined>();
  const [cortacsUpcoming, setCortacsUpcoming] =
    React.useState<CortacUpcomingQueryResponse | undefined>();
  const [allCortacsUpcoming, setAllCortacsUpcoming] =
    React.useState<CortacUpcoming[] | undefined>(undefined);
  const [allVoucherContracts, setAllVoucherContracts] =
    React.useState<VoucherContract[] | undefined>(undefined);
  const auth = useAuth();
  const defaultQuery = { page: 0, pageSize: PageSize.MEDIUM, term: '' };
  const [summary, setSummary] = useState({
    totalNumber: 0,
    needsToSettle: 0,
    totalAmount: 0,
    totalAmountRemainingToSettle: 0,
  });

  const isPendingContract = (row: CortacUpcoming): boolean => {
    return row.amountPaid < row.amountTotal;
  };

  const calculateNeedsToSettle = (rows: CortacUpcoming[]): number => {
    return (
      rows.filter((row) => {
        return isPendingContract(row);
      }).length || 0
    );
  };

  const calculateTotalCount = (rows: CortacUpcoming[]): number => {
    return rows.reduce((sum, current) => sum + current.count, 0);
  };

  const calculateTotalAmount = (rows: CortacUpcoming[]): number => {
    return rows.reduce((sum, current) => sum + current.amountTotal, 0);
  };

  const calculateTotalAmountRemainingToSettle = (rows: CortacUpcoming[]): number => {
    return rows.reduce((sum, current) => sum + (current.amountTotal - current.amountPaid), 0);
  };

  const calculateSummary = (allRows: CortacUpcoming[]) => {
    const totalNumber = calculateTotalCount(allRows);
    const needsToSettle = calculateNeedsToSettle(allRows);
    const totalAmount = calculateTotalAmount(allRows);
    const totalAmountRemainingToSettle = calculateTotalAmountRemainingToSettle(allRows);

    setSummary({
      totalNumber,
      needsToSettle,
      totalAmount,
      totalAmountRemainingToSettle,
    });
  };

  const checkQuery = (q: Query): boolean => {
    if (q.query) {
      if (Object.keys(q.query).length === 0) {
        return Object.keys(lastQuery?.query || {}).length !== 0;
      }
      const qVal = JSON.stringify(q.query);
      const queryVal = JSON.stringify(lastQuery?.query);
      return qVal !== queryVal;
    }
    return false;
  };

  const checkRunAction = (query: Query): boolean => {
    return (
      lastQuery?.page !== query?.page ||
      lastQuery?.pageSize !== query?.pageSize ||
      lastQuery?.sortColumn !== query?.sortColumn ||
      lastQuery?.sortDirection !== query?.sortDirection ||
      lastQuery?.term !== query?.term ||
      checkQuery(query)
    );
  };

  const refreshList = () => {
    setRefreshQuery(true);
    setLoading(true);
  };

  const queryUpcomingCortacs = (q: Query) => {
    const filtered = filterCortacs(allCortacsUpcoming || [], q);

    const cortacs = {
      total: filtered.length || 0,
      results: trimCortacs(filtered, q),
      page: q.page,
      pageSize: q.pageSize,
      totalPages: getPageTotal(filtered.length, q.pageSize || 1),
    };

    setCortacsUpcoming(cortacs);
    calculateSummary(filtered);
  };

  const getPastCortacs = (q: Query = { ...defaultQuery }) => {
    if ((q && checkRunAction(q)) || refreshQuery) {
      setLoading(true);
      setLoaded(false);
      setLastQuery(q);

      const query = { ...q.query, ...(q.term && { term: q.term }) } as Query;
      const request: CortacSubmissionsQueryRequest = {
        page: q.page + 1,
        pageSize: q.pageSize,
        query,
        ...(q.sortColumn && { sortColumn: q.sortColumn }),
        ...(q.sortDirection && { sortDirection: q.sortDirection }),
      };

      CortacService.query(request)
        .then((result: CortacSubmissionsQueryResponse | AxiosError) => {
          const data: CortacSubmissionsQueryResponse = result as CortacSubmissionsQueryResponse;
          setHasError(false);
          setLoaded(true);
          setLoading(false);
          setCortacsPast(data);
        })
        .catch((err: AxiosError) => {
          setHasError(true);
          setErrorMessage(err.message);
          setLoaded(false);
          setLoading(false);

          if (err.response?.status === 401) {
            auth.setRequiresAuth();
          }
        })
        .finally(() => {
          setLoading(false);
          setLoaded(true);
          setRefreshQuery(false);
        });
    }
  };

  const getUpcomingCortacs = (q: Query = { ...defaultQuery }) => {
    if ((q && checkRunAction(q)) || refreshQuery) {
      setLoading(true);
      setLoaded(false);
      setLastQuery(q);

      const query = { ...q.query, ...(q.term && { term: q.term }) } as Query;
      const request = {
        page: q.page + 1,
        pageSize: q.pageSize,
        query,
        ...(q.sortColumn && { sortColumn: q.sortColumn }),
        ...(q.sortDirection && { sortDirection: q.sortDirection }),
        ...(q.includes && { includes: q.includes }),
      };

      CortacService.getUpcomingCortacs(request)
        .then((response: CortacUpcomingQueryResponse | AxiosError) => {
          const results: CortacUpcoming[] = (response as CortacUpcomingQueryResponse)
            .results as CortacUpcoming[];

          setHasError(false);
          setLoaded(true);
          setLoading(false);
          setAllCortacsUpcoming(results);

          const cortacs = {
            total: results.length || 0,
            results: filterAndTrimContracts(results, q),
            page: q.page,
            pageSize: q.pageSize,
            totalPages: getPageTotal(results.length, q.pageSize || 1),
          };

          setCortacsUpcoming(cortacs);
          calculateSummary(results);
        })
        .catch((err: AxiosError) => {
          setHasError(true);
          setErrorMessage(err.message);
          setLoaded(false);
          setLoading(false);

          if (err.response?.status === 401) {
            auth.setRequiresAuth();
          }
        })
        .finally(() => {
          setLoading(false);
          setLoaded(true);
          setRefreshQuery(false);
        });
    }
  };

  const getCortacById = async (
    id: string
  ): Promise<CortacSubmissionResponse | AxiosError | unknown> => {
    setCortacLoading(true);
    setCortacLoaded(false);

    return new Promise(async (resolve, reject) => {
      CortacService.getCoratcById(id)
        .then((resp) => {
          setCortacLoading(false);
          setCortacLoaded(true);
          resolve(resp);
        })
        .catch((err: AxiosError) => {
          reject(err.message);
          setCortacLoading(false);
        });
    });
  };

  const getInstallments = (taxRollId: string, taxYear: number): Promise<number[] | AxiosError> => {
    const request: PaymentContractsRequest = {
      page: 1,
      pageSize: 99999,
      query: {
        taxRollId,
        taxYear: {
          eq: taxYear,
        },
      },
    };
    setInstallmentsLoading(true);

    return new Promise(async (resolve, reject) => {
      getPaymentContracts(request)
        .then((resp: PaymentContractsResult | AxiosError) => {
          setInstallmentsLoaded(true);
          setInstallmentsLoading(false);

          // @ts-ignore
          const installments = resp?.results?.map((pc) =>
            pc.installments.map((i: { installmentNumber: number }) => i.installmentNumber)
          );
          resolve(uniq(flattenDeep(installments)));
        })
        .catch((err: AxiosError) => {
          reject(err.message);
          setInstallmentsLoading(false);
        });
    });
  };

  const submit = async (cortac: GenerateCortac): Promise<CortacResponse | AxiosError | unknown> => {
    return new Promise(async (resolve, reject) => {
      CortacService.submit(cortac)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err: AxiosError) => {
          reject(err.message);
        });
    });
  };

  const preview = async (
    cortac: GenerateCortac
  ): Promise<CortacResponse | AxiosError | unknown> => {
    return new Promise(async (resolve, reject) => {
      CortacService.preview(cortac)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err: AxiosError) => {
          reject(err.message);
        });
    });
  };

  const getFileContent = async (path: string): Promise<string | AxiosError | unknown> => {
    return new Promise(async (resolve, reject) => {
      CortacService.fileContent(path)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err: AxiosError) => {
          reject(err.message);
        });
    });
  };

  const clearAllUpcomingCortacs = () => {
    setAllCortacsUpcoming(undefined);
    setAllVoucherContracts([]);
    setSummary({
      totalNumber: 0,
      needsToSettle: 0,
      totalAmount: 0,
      totalAmountRemainingToSettle: 0,
    });
  };

  const clearPastCortacs = () => {
    setCortacsPast(undefined);
  };

  const value = {
    loading,
    loaded,
    cortacLoading,
    cortacLoaded,
    hasError,
    errorMessage,
    cortacsPast,
    cortacsUpcoming,
    refreshList,
    getPastCortacs,
    getUpcomingCortacs,
    getCortacById,
    submit,
    preview,
    getFileContent,
    queryUpcomingCortacs,
    clearAllUpcomingCortacs,
    clearPastCortacs,
    installmentsLoading,
    installmentsLoaded,
    getInstallments,
    allCortacsUpcoming,
    allVoucherContracts,
    summary,
  };
  return <CortacsContext.Provider value={value}>{children}</CortacsContext.Provider>;
}

export function useCortacs() {
  return React.useContext(CortacsContext);
}
