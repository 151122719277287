import { ROUTES } from '../constants/routes';
import {
  CortacResponse,
  CortacSubmissionResponse,
  CortacSubmissions,
  CortacSubmissionsQueryRequest,
  CortacSubmissionsQueryResponse,
  CortacUpcomingQueryRequest,
  CortacUpcomingQueryResponse,
  GenerateCortac,
} from '../models/Cortac';
import { getAxios } from './espAxios';
import { AxiosError, AxiosResponse } from 'axios';

const ROOT_PATH_CORTACS = ROUTES.CORTACS;
const ROOT_PATH_CORTACS_SUBMISSIONS = ROUTES.CORTAC_SUBMISSIONS;

export namespace CortacService {
  export function submit(cortac: GenerateCortac): Promise<CortacResponse | AxiosError> {
    return getAxios()
      .post<CortacResponse | AxiosError>(`${ROOT_PATH_CORTACS}/submit`, cortac)
      .then((resp: AxiosResponse<CortacResponse | AxiosError>) => resp.data)
      .catch((error: AxiosError) => {
        throw error;
      });
  }
  export function preview(cortac: GenerateCortac): Promise<CortacResponse | AxiosError> {
    return getAxios()
      .post<CortacResponse | AxiosError>(`${ROOT_PATH_CORTACS}/preview`, cortac)
      .then((resp: AxiosResponse<CortacResponse | AxiosError>) => resp.data)
      .catch((error: AxiosError) => {
        throw error;
      });
  }
  export function getCoratcById(id: string): Promise<CortacSubmissionResponse | AxiosError> {
    return getAxios()
      .get<CortacSubmissionResponse | AxiosError>(`${ROOT_PATH_CORTACS}/${id}`)
      .then((resp: AxiosResponse<CortacSubmissionResponse | AxiosError>) => resp.data)
      .catch((error: AxiosError) => {
        throw error;
      });
  }
  export function getById(id: string): Promise<CortacSubmissions | AxiosError> {
    return getAxios()
      .get<CortacSubmissions | AxiosError>(`${ROOT_PATH_CORTACS_SUBMISSIONS}/${id}`)
      .then((resp: AxiosResponse<CortacSubmissions | AxiosError>) => resp.data)
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  export function query(
    request: CortacSubmissionsQueryRequest
  ): Promise<CortacSubmissionsQueryResponse | AxiosError> {
    return getAxios()
      .post<CortacSubmissionsQueryResponse | AxiosError>(
        `${ROOT_PATH_CORTACS_SUBMISSIONS}/query`,
        request
      )
      .then((resp: AxiosResponse<CortacSubmissionsQueryResponse | AxiosError>) => resp.data)
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  export function fileContent(path: string): Promise<string | AxiosError> {
    return getAxios()
      .get<string | AxiosError>(`${ROOT_PATH_CORTACS_SUBMISSIONS}/file-content?path=${path}`)
      .then((resp: AxiosResponse<string | AxiosError>) => resp.data)
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  export function getUpcomingCortacs(
    request: CortacUpcomingQueryRequest
  ): Promise<CortacUpcomingQueryResponse | AxiosError> {
    return getAxios()
      .post<CortacUpcomingQueryResponse | AxiosError>(
        `${ROOT_PATH_CORTACS}/get-upcoming-cortacs`,
        request
      )
      .then((resp: AxiosResponse<CortacUpcomingQueryResponse | AxiosError>) => resp.data)
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}
