import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import {
  cancelContract,
  doRerunPayment,
  getPaymentContractByOrderId,
  getPaymentContracts,
  getSingleRetryPayment,
  queryRetryPayments,
  reindex,
  sendPaymentDetailsEmail,
} from '../services/paymentContracts';
import { useAuth } from './authContext';
import {
  CancelPaymentContractRequest,
  PaymentContract,
  PaymentContractsRequest,
  PaymentContractsResult,
  PaymentContractStatus,
  ProcessRecurringPaymentRequest,
  RetryPaymentDto,
  RetryPaymentQueryResponse,
  TablePaymentContracts,
} from '../models/PaymentContract';
import { Query } from '../models/Query';
import {
  PaymentContractQueryResults,
  RetryPaymentQueryResults,
} from '../models/PaymentContractQueryResults';
import { mapPaymentContracts } from '../helpers/mapPaymentContracts';
import { DefaultContext } from '../models/Context';
import { AvailableExportColumn } from '../components/Tables/ExportColumnChooser';

interface PaymentContractsContextType extends DefaultContext {
  queryPaymentContracts: (q: Query) => void;
  queryPaymentContractsForExport: (q: Query) => void;
  getPaymentContractById: (id: string) => Promise<PaymentContract | undefined>;
  results?: PaymentContractQueryResults;
  exportResults?: TablePaymentContracts[];
  query: Query | null;
  cancelPaymentContract: (
    request: CancelPaymentContractRequest
  ) => Promise<boolean | AxiosError<any, any>>;
  rerunPayment: (
    request: ProcessRecurringPaymentRequest
  ) => Promise<boolean | AxiosError<any, any>>;
  reindexPayment: (id: string) => Promise<string | AxiosError>;
  setColumnsToExport: (c: AvailableExportColumn[]) => void;
  columnsToExport: AvailableExportColumn[];
  refreshContract: boolean;
  refreshList: (value: boolean) => void;

  retryLoading: boolean;
  retryLoaded: boolean;
  retryHasError: boolean;

  queryRetriesPayment: (q: Query) => void;
  queryRetriesPaymentForExport: (q: Query) => void;
  retryPaymentResults?: RetryPaymentQueryResults;
  retryExportResults?: RetryPaymentDto[];
  setRetryColumnsToExport: (c: AvailableExportColumn[]) => void;
  retryColumnsToExport: AvailableExportColumn[];
  getRetryPaymentById: (id: string) => Promise<RetryPaymentDto>;
  sendDetailsPaymentEmail: (id: string) => Promise<{ success: boolean }>;
}

function getPageTotal(paymentContractsSize: number, pageSize: number) {
  // const paymentContractsSize = paymentContracts?.length || 0;

  if (paymentContractsSize === 0) {
    return 0;
  }

  return Math.round(paymentContractsSize / pageSize);
}

let PaymentContractsContext = createContext<PaymentContractsContextType>(null!);

export function PaymentContractsProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [results, setResults] = useState<PaymentContractQueryResults | undefined>();
  const [query, setQuery] = useState<Query | null>(null);
  const [exportQuery, setExportQuery] = useState<Query | null>(null);
  const [exportResults, setExportResults] = useState<TablePaymentContracts[] | undefined>([]);
  const [columnsToExport, setColumns] = useState<AvailableExportColumn[]>([]);
  const [refreshContract, setRefreshContract] = useState<boolean>(false);
  const auth = useAuth();
  const [refreshQuery, setRefreshQuery] = React.useState<boolean>(false);

  const [retryLoading, setRetryLoading] = useState<boolean>(false);
  const [retryLoaded, setRetryLoaded] = useState<boolean>(false);
  const [retryHasError, setRetryHasError] = useState<boolean>(false);
  const [retryExportQuery, setRetryExportQuery] = useState<Query | null>(null);
  const [retryExportResults, setRetryExportResults] = useState<RetryPaymentDto[] | undefined>([]);
  const [retryQuery, setRetryQuery] = useState<Query | null>(null);
  const [retryPaymentResults, setRetryPaymentResults] = useState<RetryPaymentQueryResults>();
  const [retryColumnsToExport, setRetryColumns] = useState<AvailableExportColumn[]>([]);

  useEffect(() => {
    if (!query) {
      return;
    }
    setLoading(true);
    setLoaded(false);
    if (results) {
      // force a loading state between pages
      // otherwise the table will show the previous page
      // after the user clicked the next or previous button
      setResults({
        ...results,
        rows: undefined,
      });
    }

    const q = { ...query.query, ...(query.term && { term: query.term }) };

    const request: PaymentContractsRequest = {
      // MUI's data grid starts with page 0
      // the server starts with page 1
      page: query.page + 1,
      pageSize: query.pageSize,
      query: q,
      ...(query.sortColumn && { sortColumn: query.sortColumn }),
      ...(query.sortDirection && { sortDirection: query.sortDirection }),
    };
    getPaymentContracts(request)
      .then((result) => {
        const data: PaymentContractsResult = result as PaymentContractsResult;

        setResults({
          total: data.total,
          query: query,
          rows: mapPaymentContracts(data.results),
          // MUI's data grid starts with page 0
          // the server starts with page 1
          page: data.page - 1,
          pageSize: data.pageSize || 10,
          totalPages: getPageTotal(data.results.length || 0, data.pageSize || 10),
        });

        setLoaded(true);
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        setHasError(true);
        setErrorMessage(err.message);
        setLoading(false);
        setLoaded(false);

        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      });
  }, [query]);

  // separated use effect not to mess with original page size and results
  useEffect(() => {
    if (!exportQuery) {
      return;
    }

    const request: PaymentContractsRequest = {
      // MUI's data grid starts with page 0
      // the server starts with page 1
      page: 1,
      pageSize: exportQuery.pageSize,
      query: exportQuery.query || {},
      ...(exportQuery.sortColumn && { sortColumn: exportQuery.sortColumn }),
      ...(exportQuery.sortDirection && { sortDirection: exportQuery.sortDirection }),
    };

    getPaymentContracts(request)
      .then((result) => {
        const data: PaymentContractsResult = result as PaymentContractsResult;
        setExportResults(mapPaymentContracts(data.results));
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      });
  }, [exportQuery]);

  const setColumnsToExport = (c: AvailableExportColumn[]) => {
    setColumns(c);
  };

  useEffect(() => {
    if (!retryQuery) {
      return;
    }
    setRetryLoading(true);
    setRetryLoaded(false);
    if (retryPaymentResults) {
      // force a loading state between pages
      // otherwise the table will show the previous page
      // after the user clicked the next or previous button
      setResults({
        ...retryPaymentResults,
        rows: undefined,
      });
    }

    const q = { ...retryQuery.query, ...(retryQuery.term && { term: retryQuery.term }) };

    if (!retryQuery.sortColumn) {
      retryQuery.sortColumn = 'status';
    }

    if (!retryQuery.sortDirection) {
      retryQuery.sortDirection = 'ASC';
    }

    const request: PaymentContractsRequest = {
      // MUI's data grid starts with page 0
      // the server starts with page 1
      page: retryQuery.page + 1,
      pageSize: retryQuery.pageSize,
      query: q,
      ...(retryQuery.sortColumn && { sortColumn: retryQuery.sortColumn }),
      ...(retryQuery.sortDirection && { sortDirection: retryQuery.sortDirection }),
    };

    queryRetryPayments(request)
      .then((result) => {
        const data: RetryPaymentQueryResponse = result as RetryPaymentQueryResponse;

        setRetryPaymentResults({
          total: data.total,
          query: retryQuery,
          rows: data.results,
          // MUI's data grid starts with page 0
          // the server starts with page 1
          page: data.page - 1,
          pageSize: data.pageSize || 10,
          totalPages: getPageTotal(data.results.length || 0, data.pageSize || 10),
        });

        setRetryLoaded(true);
        setRetryLoading(false);
      })
      .catch((err: AxiosError) => {
        setRetryHasError(true);
        setRetryLoading(false);
        setRetryLoaded(false);

        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      });
  }, [retryQuery]);

  // RETRY EXPORT QUERY EFFECT
  useEffect(() => {
    if (!retryExportQuery) {
      return;
    }

    const request: PaymentContractsRequest = {
      // MUI's data grid starts with page 0
      // the server starts with page 1
      page: 1,
      pageSize: retryExportQuery.pageSize,
      query: retryExportQuery.query || {},
      ...(retryExportQuery.sortColumn && { sortColumn: retryExportQuery.sortColumn }),
      ...(retryExportQuery.sortDirection && { sortDirection: retryExportQuery.sortDirection }),
    };

    queryRetryPayments(request)
      .then((result) => {
        const data: RetryPaymentQueryResponse = result as RetryPaymentQueryResponse;

        setRetryExportResults(data.results);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      });
  }, [retryExportQuery]);

  const checkQuery = (q: Query, existingQuery: Query | null = null): boolean => {
    if (q.query) {
      if (Object.keys(q.query).length === 0) {
        return Object.keys(existingQuery?.query || {}).length !== 0;
      }
      const qVal = JSON.stringify(q.query);
      const queryVal = JSON.stringify(existingQuery?.query);
      return qVal !== queryVal;
    }
    return false;
  };

  const queryPaymentContracts = (q: Query) => {
    const termChanged = q.term || (query?.term && q.term !== query?.term);

    if (q.term) {
      if (!q.query) {
        q.query = {
          term: q.term,
        };
      }
    } else {
      if (q.query) {
        delete q.query.term;
      }
    }

    if (
      q.page !== query?.page ||
      q.pageSize !== query?.pageSize ||
      q.sortColumn !== query?.sortColumn ||
      q.sortDirection !== query?.sortDirection ||
      termChanged ||
      checkQuery(q) ||
      refreshQuery
    ) {
      setQuery(q);
    }
  };

  const queryPaymentContractsForExport = (q: Query) => {
    setExportQuery(q);
  };

  const setRetryColumnsToExport = (c: AvailableExportColumn[]) => {
    setRetryColumns(c);
  };

  const getPaymentContractById = async (id: string): Promise<TablePaymentContracts | undefined> => {
    // check if we already have the payment contract
    let contract = results?.rows?.find((c) => c.orderId === id);
    // if there isn't a contract
    // make an api request to get it
    if (!contract) {
      const data = (await getPaymentContractByOrderId(id)) as PaymentContract;
      contract = mapPaymentContracts([data])[0];
    }
    setRefreshContract(false);
    return contract;
  };

  const sendDetailsPaymentEmail = async (id: string): Promise<{ success: boolean }> => {
    return sendPaymentDetailsEmail(id).then(
      () => {
        return { success: true };
      },
      () => {
        return { success: false };
      }
    );
  };

  const cancelPaymentContract = async (
    request: CancelPaymentContractRequest
  ): Promise<boolean | AxiosError<any, any>> => {
    return cancelContract(request)
      .then(() => {
        if (results?.rows) {
          const newResults = { ...results };
          newResults.rows = newResults.rows?.map((x: TablePaymentContracts) => {
            if (x.orderId === request.orderId) {
              x.status = PaymentContractStatus.CANCELED;
            }
            return x;
          });
        }
        return true;
      })
      .catch((err: AxiosError) => {
        if (err?.message) {
          return err;
        }
        return false;
      });
  };

  const rerunPayment = async (request: ProcessRecurringPaymentRequest) => {
    return doRerunPayment(request)
      .then((resp) => {
        const status = resp as { success: boolean };
        setRefreshContract(true);
        return status.success;
      })
      .catch(() => {
        setRefreshContract(true);
        return false;
      });
  };

  const reindexPayment = async (id: string) => {
    return reindex(id);
  };

  const refreshList = (value: boolean): void => {
    setRefreshQuery(value);

    if (value) {
      const newQuery = {
        ...query,
        ...{
          query: {},
        },
      } as Query;
      setQuery(newQuery);
    }
  };

  const getRetryPaymentById = async (id: string): Promise<RetryPaymentDto> => {
    return (await getSingleRetryPayment(id)) as RetryPaymentDto;
  };

  const queryRetriesPayment = (q: Query) => {
    const termChanged = q.term || (retryQuery?.term && q.term !== retryQuery?.term);

    if (q.term) {
      if (!q.query) {
        q.query = {
          term: q.term,
        };
      }
    } else {
      if (q.query) {
        delete q.query.term;
      }
    }

    if (
      q.page !== retryQuery?.page ||
      q.pageSize !== retryQuery?.pageSize ||
      q.sortColumn !== retryQuery?.sortColumn ||
      q.sortDirection !== retryQuery?.sortDirection ||
      termChanged ||
      checkQuery(q, retryQuery)
    ) {
      setRetryQuery(q);
    }
  };

  const queryRetriesPaymentForExport = (q: Query) => {
    setRetryExportQuery(q);
  };

  const value = {
    loading,
    loaded,
    hasError,
    errorMessage,
    queryPaymentContracts,
    queryPaymentContractsForExport,
    getPaymentContractById,
    results,
    exportResults,
    query,
    cancelPaymentContract,
    rerunPayment,
    reindexPayment,
    setColumnsToExport,
    columnsToExport,
    refreshContract,
    refreshList,
    retryLoading,
    retryLoaded,
    retryHasError,
    queryRetriesPayment,
    queryRetriesPaymentForExport,
    retryPaymentResults,
    retryExportResults,
    setRetryColumnsToExport,
    retryColumnsToExport,
    getRetryPaymentById,
    sendDetailsPaymentEmail,
  };

  return (
    <PaymentContractsContext.Provider value={value}>{children}</PaymentContractsContext.Provider>
  );
}

export function usePaymentContracts() {
  return useContext(PaymentContractsContext);
}
