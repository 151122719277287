import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Search from 'src/global/components/Search/Search';
import { PageSize } from 'src/global/components/Tables/GenericTable';
import ContractTable from 'src/global/components/Tables/ContractTable';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { ROUTES } from 'src/global/constants/routes';
import { usePaymentContracts } from 'src/global/contexts/paymentContractsContext';
import { TablePaymentContracts } from 'src/global/models/PaymentContract';
import { Query } from 'src/global/models/Query';
import { AvailableExportColumn } from '../../../global/components/Tables/ExportColumnChooser';
import { prepareCSVData } from '../../../global/helpers/csvFile';
import { downloadTextAsFile } from '../../../global/helpers/downloadFile';
import { getUrlQuery, updateUrlQuery } from '../../../global/helpers/queryHelper';
import { commonStyle } from '../../../global/constants/common.style';

interface PaymentContractListProps {
  queryParams?: Query | null;
  showSearch?: boolean;
}
const PaymentContractList: FunctionComponent<PaymentContractListProps> = ({
  queryParams = null,
  showSearch = true,
}) => {
  const navigate = useNavigate();
  const paymentContractsContext = usePaymentContracts();
  const [paymentContractResults, setPaymentContractResults] = useState<TablePaymentContracts[]>([]);
  const [columnsToExport, setColumnsToExport] = useState<AvailableExportColumn[]>([]);
  const [paymentContractsTotal, setPaymentContractTotal] = useState(0);
  const [exporting, setExporting] = useState(false);
  const [exported, setExported] = useState(false);
  const [query, setQuery] = useState<Query>(() => {
    return getUrlQuery('createdAt', 'DESC');
  });

  useEffect(() => {
    if (queryParams) {
      setQuery(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    paymentContractsContext.queryPaymentContracts(query);
    updateUrlQuery(query, navigate);
  }, [query, navigate]);

  useEffect(() => {
    setPaymentContractResults(paymentContractsContext.results?.rows || []);
  }, [paymentContractsContext.results?.rows]);

  useEffect(() => {
    setPaymentContractTotal(paymentContractsContext.results?.total || 0);
  }, [paymentContractsContext.results?.total]);

  useEffect(() => {
    if (columnsToExport.length) {
      setExported(false);
      setExporting(true);
      paymentContractsContext.setColumnsToExport(columnsToExport);
      paymentContractsContext.queryPaymentContractsForExport({
        ...query,
        ...{ pageSize: paymentContractsTotal },
      });
    }
  }, [columnsToExport]);

  useEffect(() => {
    if (paymentContractsContext.exportResults?.length && columnsToExport.length) {
      downloadTextAsFile(
        prepareCSVData(columnsToExport, paymentContractsContext.exportResults),
        'Payment Contract.csv'
      );
      setColumnsToExport([]);
      setExporting(false);
      setExported(true);
    }
  }, [paymentContractsContext.exportResults, columnsToExport]);

  const handleSearchTermChange = useCallback(
    (term: string) => {
      setQuery({ ...query, ...{ page: 0 }, term, ...{ query: { term } } });
    },
    [query]
  );

  const handleColumnsToExportChange = useCallback((cols: AvailableExportColumn[]) => {
    const orderDate = cols.find((c) => c.field === 'orderDate');

    if (orderDate) {
      orderDate.field = 'createdAt';
    }
    setColumnsToExport(cols);
  }, []);

  return (
    <div>
      {showSearch && (
        <div style={commonStyle.search}>
          <Search onTermChange={handleSearchTermChange} term={query?.term || ''} />
        </div>
      )}

      <ContractTable
        rows={paymentContractResults}
        rowsPerPageOptions={[PageSize.SMALL, PageSize.MEDIUM, PageSize.LARGE]}
        onCellClick={(cellParams: GridCellParams) => {
          navigate(`${ROUTES.PAYMENT_CONTRACTS}/${cellParams.row.orderId}`);
        }}
        query={query}
        exportData={handleColumnsToExportChange}
        exporting={exporting}
        exported={exported}
        rowCount={paymentContractsContext.results?.total || 1}
        updateQuery={setQuery}
        loading={paymentContractsContext.loading}
      />
    </div>
  );
};

export default PaymentContractList;
